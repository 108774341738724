import React from "react";
import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

const HomeLayout = () => {

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Header/>
      <Box display="flex" flexGrow={1}>
        <Sidebar />
        <Box padding={8} flexGrow={1} backgroundColor="#F5F5F5">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default HomeLayout;
