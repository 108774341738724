import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Badge,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import data from "./dataCandidates.json";

const TableCandidates: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataList, setDataList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;
  const pagesVisited = pageNumber * itemsPerPage;

  useEffect(() => {
    setDataList(data);
    setFilteredData(data);
  }, []);

  console.log(filteredData);
  const displayData = filteredData
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => (
      <Tr key={item.id}>
        <Td>{item.Name}</Td>
        <Td>{item.Skill}</Td>
        <Td>{item.Seniority}</Td>
        <Td>{item.Score}</Td>
        <Td>{item.English}</Td>
        <Td>{item.SalaryExp}</Td>
        <Td>{item.Country}</Td>
        <Td>
          {item.isActive ? (
            <Badge
              color="#00B833"
              bg="#E8FFDD"
              padding={"5px 15px"}
              borderRadius={"4px"}
            >
              Active
            </Badge>
          ) : (
            <Badge
              color="#FF0000"
              bg="#FFDDDD"
              padding={"5px 15px"}
              borderRadius={"4px"}
            >
              Not Active
            </Badge>
          )}
        </Td>
        <Td>
          <Button size="sm" borderRadius="8px" bg="#016DDA" color="#FFFFFF">
            <img src="/public/Icons/download.svg" />
          </Button>
        </Td>
      </Tr>
    ));

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    const filteredResults = dataList.filter((item) =>
      item.Name.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  return (
    <Box>
      <Box justifyContent="space-between" display="flex" width="100%">
        Candidates
        <Box justifyContent="space-between" display="flex" gap="4px">
          <Input
            placeholder="Find by name..."
            onChange={handleSearch}
            marginBottom={4}
            width="50%"
          />
          <Button
            borderRadius="8px"
            bg="#016DDA"
            color="#FFFFFF"
            onClick={onOpen}
          >
            Download Candidates
          </Button>
        </Box>
      </Box>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Skill</Th>
            <Th>Seniority</Th>
            <Th>Score</Th>
            <Th>English</Th>
            <Th>Salary Exp.</Th>
            <Th>Country</Th>
            <Th>Status</Th>
            <Th>CV</Th>
          </Tr>
        </Thead>
        <Tbody>{displayData}</Tbody>
      </Table>
    </Box>
  );
};

export default TableCandidates;
