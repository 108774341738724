import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import SignupConfirmation from "./pages/SignupConfirmation";
import VerifyEmail from "./pages/VerifyEmail";
import Home from "./pages/Home";
import LoginProcess from "./pages/LoginProcess";
import HomeLayout from "./pages/HomeLayout";
import { AuthContext } from "./context";
import PersonalInformation from "./pages/PersonalInformation";
import SettingsLayout from "./pages/SettingsLayout";
import Notifications from "./pages/Notifications";
import ChangePassword from "./pages/ChangePassword";
import DesiredSalary from "./pages/DesiredSalary";
import Admin from "./pages/Admin";

function App() {
  const { loginToken, userData } = useContext(AuthContext);
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          {!loginToken && <Route path="/" element={<Login />} />}
          {!loginToken && <Route path="/signup" element={<Signup />} />}
          {!loginToken && <Route path="/admin" element={<Admin />} />}
          {!loginToken && (
            <Route
              path="/signup-confirmation/:email"
              element={<SignupConfirmation />}
            />
          )}
          {!loginToken && (
            <Route
              path="/verify-email/:email/:emailCode"
              element={<VerifyEmail />}
            />
          )}
          {loginToken && userData && userData.isAuthenticated && (
            <Route path="/" element={<HomeLayout />}>
              <Route index element={<Home />} />
              <Route path="settings" element={<SettingsLayout />}>
                <Route path="notifications" element={<Notifications />} />
                <Route path="desired-salary" element={<DesiredSalary />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route index element={<PersonalInformation />} />
              </Route>
            </Route>
          )}

          {loginToken && !(userData && userData.isAuthenticated) && (
            <Route path="/" element={<LoginProcess />} />
          )}

          <Route
            path="*"
            element={<Navigate to={loginToken ? "/home" : "/"} />}
          />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
