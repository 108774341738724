import React from "react";
import {
  Box,
  Flex,
  Image,
  Heading,
  Stack,
  FormControl,
  Input,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ReactCountryFlag from "react-country-flag";

const PersonalInformation = () => {
  return (
    <Box width="100%" bg="white" borderRadius="12px">
      <Box p={5}>
        <Heading fontSize={24} mb={4} color="#49424D">Personal Information</Heading>
        <Stack spacing={4}>
          <HStack spacing={4}>
            <FormControl isRequired>
              <Input
                placeholder="First Name"
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
            </FormControl>

            <FormControl isRequired>
              <Input
                placeholder="Last Name"
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
            </FormControl>
          </HStack>

          <FormControl isRequired>
            <Input
              placeholder="Address"
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            />
          </FormControl>

          <HStack spacing={4}>
            <FormControl isRequired>
              <Input
                placeholder="Zip Code"
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
            </FormControl>

            <FormControl isRequired>
              <Menu>
                <MenuButton
                  as={Button}
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                  pr={16}
                  width="100%"
                  position="relative"
                  _hover={{
                    background: "white",
                    border: "1px solid #E5E6E6",
                  }}
                  _active={{
                    background: "white",
                    border: "1px solid #E5E6E6",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Flex direction="row" fontWeight={400}>
                      <ReactCountryFlag
                        countryCode="US"
                        svg
                        style={{
                          width: "1.5em",
                          height: "1.5em",
                          marginRight: "8px",
                        }}
                      />
                      Select country
                    </Flex>
                  </Box>
                  <ChevronDownIcon
                    position="absolute"
                    right="12px"
                    top="50%"
                    transform="translateY(-50%)"
                  />
                </MenuButton>
                <MenuList maxH="200px" overflowY="auto">
                  <MenuItem display="flex" alignItems="center">
                    <ReactCountryFlag
                      countryCode="US"
                      svg
                      style={{
                        width: "1.5em",
                        height: "1.5em",
                        marginRight: "8px",
                      }}
                    />
                    United States
                  </MenuItem>
                </MenuList>
              </Menu>
            </FormControl>
          </HStack>

          <FormControl required>
            <Flex direction="row" gap={2} width="100%">
              <Menu>
                <MenuButton
                  as={Button}
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                  width="30%"
                  textAlign="left"
                  position="relative" // Add relative positioning for absolute icon
                  pr={16} // Add padding to the right to make space for the icon
                  _hover={{
                    background: "white",
                    border: "1px solid #E5E6E6",
                  }}
                  _active={{
                    background: "white",
                    border: "1px solid #E5E6E6",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Flex direction="row" fontWeight={400}>
                      <ReactCountryFlag
                        countryCode="US"
                        svg
                        style={{
                          width: "1.5em",
                          height: "1.5em",
                          marginRight: "8px",
                        }}
                      />
                      Code
                    </Flex>
                  </Box>
                  <ChevronDownIcon
                    position="absolute"
                    right="12px"
                    top="50%"
                    transform="translateY(-50%)" // Center the icon vertically
                  />
                </MenuButton>

                <MenuList maxH="200px" overflowY="auto">
                  <MenuItem display="flex" alignItems="center">
                    <Box marginRight="10px">
                      <Image
                        src={`/images/flags/US.svg`}
                        alt={`United States flag`}
                        style={{ width: "20px", height: "auto" }}
                      />
                    </Box>
                    +1
                  </MenuItem>
                  {/* Add more country codes as needed */}
                </MenuList>
              </Menu>

              <Input
                name="phoneNumber"
                type="tel"
                placeholder="Phone number"
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
                width="70%" // Allow Input to take the remaining space
              />
            </Flex>
          </FormControl>
          <FormControl isRequired>
            <Input
              type="date"
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            />
          </FormControl>

          <FormControl isRequired>
            <Input
              placeholder="LinkedIn URL"
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            />
          </FormControl>

          <Flex justifyContent="flex-end" mt={4} px={4}>
            <Button
              px={6}
              sx={{
                borderRadius: "8px",
                background: "#016DDA",
                color: "white",
                textAlign: "center",
                fontFamily: "Segoe UI",
                fontSize: "14px",
                fontWeight: 600,
                _hover: { background: "#004AA4" },
              }}
            >
              Edit
            </Button>
          </Flex>
        </Stack>
      </Box>
    </Box>
  );
};

export default PersonalInformation;
