import React, { useMemo } from "react";
import {
  Box,
  Button,
  FormControl,
  Select,
  Stack,
  HStack,
  IconButton,
  Heading,
  Text,
  Flex,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useForm, useFieldArray } from "react-hook-form";
import skills from "../../../data/skills"; // Assuming skills is an array of strings
import notify from "../../../utils/notify"; // Import your notify function

const Skills = ({ handleNext, addSkills, handlePrevious }) => {
  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      skills: [], // Default empty array for skills
    },
  });

  // useFieldArray to dynamically manage the skills array
  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  // Filter out skills that have already been added
  const availableSkills = useMemo(() => {
    const selectedSkillNames = fields.map((skill) => skill.skillName);
    return skills.filter((skill) => !selectedSkillNames.includes(skill));
  }, [fields]);

  // Handle form submission
  const onSubmit = async (data) => {
    const selectedSkills = data.skills.map((skill) => ({
      skillName: skill.skillName,
      score: skill.score,
    }));

    try {
      await addSkills(selectedSkills); // Pass selected skills to addSkills function
      handleNext(); // Proceed to the next step after adding skills
    } catch (error) {}
  };

  // Handle adding the selected skill
  const handleAddSkill = (skillName) => {
    if (!skillName) return; // Prevent adding if no skill is selected

    if (fields.length >= 10) {
      // Notify the user if they try to add more than 10 skills
      notify("error", "You cannot add more than 10 skills");
      return;
    }

    append({ skillName, score: 100 }); // Default score is 100
    setValue("selectedSkill", ""); // Reset the skill selection after adding
  };

  return (
    <Box px={64}>
      <Heading fontSize={40}>Skills</Heading>
      <Text color="#707887" mt={4} mb={8}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem.
      </Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} p={0}>
          {fields.length > 0 && (
            <HStack wrap="wrap" spacing={2}>
              {fields.map((skill, index) => (
                <HStack key={skill.id} spacing={0}>
                  <Button
                    size="sm"
                    variant="outline"
                    borderRadius="8px 0 0 8px" // Rounded on the left only
                    background="#E8E9E9"
                    color="#596375"
                  >
                    {skill.skillName}
                  </Button>
                  <IconButton
                    aria-label="Remove Skill"
                    size="sm"
                    icon={<CloseIcon fontSize={8}/>}
                    onClick={() => remove(index)} // Remove skill
                    borderRadius="0 8px 8px 0" // Rounded on the right only
                    background="#E8E9E9"
                    borderLeft="none" // Creates a border between button and X
                    pr={4}
                  />
                </HStack>
              ))}
            </HStack>
          )}

          {/* Wrap Select and Button inside HStack to align them horizontally */}
          <HStack spacing={4}>
            <FormControl isRequired>
              <Select
                {...register("selectedSkill")}
                placeholder="Select a skill"
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              >
                {availableSkills.map((skill, index) => (
                  <option key={index} value={skill}>
                    {skill}
                  </option>
                ))}
              </Select>
            </FormControl>

            <Button
              px={8}
              py={6}
              onClick={() =>
                handleAddSkill(
                  control._formValues.selectedSkill // Get selected skill from the form
                )
              }
              sx={{
                borderRadius: "8px",
                background: "#DCEBFA",
                color: "#016DDA",
                textAlign: "center",
                fontFamily: "Segoe UI",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                _hover: {
                  background: "#B9DBF5", // Optional: Change background on hover
                  color: "#005BB5", // Optional: Change text color on hover
                },
              }}
            >
              Add Skill +
            </Button>
          </HStack>
        </Stack>
        <Flex justifyContent="space-between" mt={8} px={0}>
          <Button
            px={7}
            sx={{
              borderRadius: "8px",
              background: "#DCEBFA",
              color: "#016DDA",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#B9DBF5", color: "#005BB5" },
            }}
            onClick={handlePrevious} // Goes back to the previous step
          >
            Back
          </Button>
          <Button
            px={7}
            sx={{
              borderRadius: "8px",
              background: "#016DDA",
              color: "white",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#004AA4" },
            }}
            onClick={handleSubmit(onSubmit)} // Trigger form submit and proceed to the next step
          >
            Next
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default Skills;
