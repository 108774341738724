import React, { useReducer, useEffect, createContext } from "react";
import axios from "axios";
import { AuthReducer } from "./reducer"; // Ensure this is set up to handle authentication-related actions
import notify from "../utils/notify"; // Import your notify function

// Initial state for the context
const initialState = {
  loginToken: null,
  userData: null,
};

// Create the context
export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  // Set the login token in state and local storage
  const setLoginToken = (loginToken) => {
    dispatch({
      type: "SET_LOGIN_TOKEN",
      payload: loginToken,
    });
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem("loginToken");
    setLoginToken(null);
    return notify("success", "Logged out successfully");
  };

  // Login function
  const login = async (email, password) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/login`,
        { email, password }
      );

      // Save the token in local storage and update the context
      const { token, authenticated } = response.data; // Assuming your response structure has token
      localStorage.setItem("loginToken", token);
      setLoginToken(token);

      if (authenticated) {
        await fetchUserData(token); // Fetch user data after setting the token
      }

      return notify("success", "Login successful!");
    } catch (error) {
      // Clear token on error
      localStorage.removeItem("loginToken");
      setLoginToken(null);
      return notify(
        "error",
        error.response && error.response.data.error
          ? error.response.data.error
          : "An unexpected error occurred. Please try again."
      );
    }
  };

  // Function to fetch user data (optional)
  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BACKEND_URL}/user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({
        type: "SET_USER_DATA",
        payload: response.data, // Assuming response data contains user data
      });
    } catch (error) {
      return notify("error", "Could not fetch user data. Please try again.");
    }
  };

  const signup = async (userData) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/signup`,
        userData
      );

      // Assuming the backend sends a confirmation message or user details
      const { message } = response.data;
      return notify("success", message); // Notify user about successful registration
    } catch (error) {
      if (error.response && error.response.data.errors) {
        // Display validation errors
        error.response.data.errors.forEach((err) => notify("error", err.msg));
      } else if (error.response && error.response.data.message) {
        return notify("error", error.response.data.message); // Show error message from backend
      } else {
        notify(
          "error",
          "An unexpected error occurred. Please try again."
        );
      }
      throw error;

    }
  };

  const resendConfirmationEmail = async (email) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/resendConfirmationEmail`,
        { email }
      );

      return notify(
        "success",
        response.data.message || "Confirmation email resent!"
      );
    } catch (error) {
      return notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error resending confirmation email. Please try again."
      );
    }
  };

  // Confirm the phone code
  const confirmPhoneCode = async (email, phoneCode) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/confirmPhoneCode`,
        { email, confirmationCode: phoneCode }
      );

      notify(
        "success",
        response.data.message || "Phone confirmation successful!"
      );
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error confirming the phone code. Please try again."
      );
      throw new Error("Confirmation Code failed");
    }
  };

  // Function to confirm the code
  const confirmEmailCode = async (email, confirmationCode) => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/confirmEmailCode`,
        { email, confirmationCode }
      );

      notify("success", response.data.message || "Confirmation successful!");
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error confirming the code. Please try again."
      );
      throw new Error("Confirmation Code failed");
    }
  };

  const uploadIdentification = async (front, back) => {
    const token = localStorage.getItem("loginToken");
    try {
      const formData = new FormData();
      formData.append("front", front);
      formData.append("back", back);

      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/uploadIdentification`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify(
        "success",
        response.data.message || "Identification uploaded successfully"
      );

      await fetchUserData(token)
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error uploading identification. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to upload identification"
      );
    }
  };

  const uploadCv = async () => {
    const token = localStorage.getItem("loginToken");
    try {
      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();

      // Add a blank page
      pdfDoc.addPage([595, 842]); // A4 size in points (width x height)

      // Serialize the PDF document to bytes (a Uint8Array)
      const pdfBytes = await pdfDoc.save();

      // Create a Blob from the PDF bytes
      const blob = new Blob([pdfBytes], { type: "application/pdf" });

      // Create a FormData object
      const formData = new FormData();
      formData.append("cv", blob, "blank.pdf"); // Specify the file name as "blank.pdf"

      // Upload the CV
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/uploadCv`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify("success", response.data.message || "CV uploaded successfully");
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error uploading CV. Please try again."
      );
      throw new Error(error.response?.data?.message || "Failed to upload CV");
    }
  };

  const addSkills = async (skills) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/addSkills`,
        { skills },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify("success", response.data.message || "Skills updated successfully");
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error adding/updating skills. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to update skills"
      );
    }
  };

  const addEnglishTestScore = async (score) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/addEnglishTest`,
        { score },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify(
        "success",
        response.data.message || "English test score updated successfully"
      );
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error adding/updating English test score. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to update English test score"
      );
    }
  };

  const updateSalariesAndFullTime = async (
    minMonthlySalary,
    maxMonthlySalary,
    fullTime
  ) => {
    const token = localStorage.getItem("loginToken");
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/addSalariesAndFullTime`,
        { minMonthlySalary, maxMonthlySalary, fullTime },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      notify(
        "success",
        response.data.message || "Account details updated successfully"
      );
    } catch (error) {
      notify(
        "error",
        error.response && error.response.data.message
          ? error.response.data.message
          : "Error updating account details. Please try again."
      );
      throw new Error(
        error.response?.data?.message || "Failed to update account details"
      );
    }
  };
  // Check for the current token and update state accordingly on initial load
  useEffect(() => {
    const token = localStorage.getItem("loginToken");
    if (token) {
      setLoginToken(token);
      fetchUserData(token); // Optionally fetch user data on app load
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        setLoginToken,
        resendConfirmationEmail,
        signup,
        confirmEmailCode,
        confirmPhoneCode,
        uploadIdentification,
        uploadCv,
        addSkills,
        addEnglishTestScore,
        updateSalariesAndFullTime,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
