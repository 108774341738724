import React from "react";
import { Box, Heading, Text, Flex, Button } from "@chakra-ui/react";

const EnglishTest = ({ handleNext, addEnglishTestScore, handlePrevious }) => {
  const addScoreWithPuntage = async () => {
    const score = 90;
    try {
      await addEnglishTestScore(score);
      handleNext();
    } catch (error) {}
  };

  return (
    <Box py={5} px={80}>
      <Heading fontSize={34} size="lg" mb={4}>
        English Test
      </Heading>
      <Text fontSize={16} color="#707887">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book.
      </Text>
      <Flex justifyContent="space-between" mt={6} px={0}>
        <Button
          px={6}
          sx={{
            borderRadius: "8px",
            background: "#DCEBFA",
            color: "#016DDA",
            textAlign: "center",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 600,
            _hover: { background: "#B9DBF5", color: "#005BB5" },
          }}
          onClick={handlePrevious}
        >
          Back
        </Button>
        <Button
          px={6}
          sx={{
            borderRadius: "8px",
            background: "#016DDA",
            color: "white",
            textAlign: "center",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 600,
            _hover: { background: "#004AA4" },
          }}
          onClick={addScoreWithPuntage}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default EnglishTest;
