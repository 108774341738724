import React, { useState, useContext } from "react";
import { Box, Button, Text, Flex, Image, VStack } from "@chakra-ui/react";
import Skills from "../../components/LoginProcess/Skills";
import JobDetails from "../../components/LoginProcess/JobDetails";
import EnglishTest from "../../components/LoginProcess/EnglishTest";
import IDCheck from "../../components/LoginProcess/IDCheck";
import CVAdding from "../../components/LoginProcess/CVAdding";
import SkillsTests from "../../components/LoginProcess/SkillsTests";
import { AuthContext } from "../../context";

const LoginProcess = () => {
  const [step, setStep] = useState(1);
  const {
    userData,
    uploadIdentification,
    uploadCv,
    addSkills,
    addEnglishTestScore,
    updateSalariesAndFullTime,
  } = useContext(AuthContext);

  // Handle switching to previous component
  const handlePrevious = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  // Handle switching to next component
  const handleNext = async () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  // List of components to render
  const steps = [
    <JobDetails
      handleNext={handleNext}
      updateSalariesAndFullTime={updateSalariesAndFullTime}
    />,
    <EnglishTest
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      addEnglishTestScore={addEnglishTestScore}
    />,
    <Skills
      handleNext={handleNext}
      addSkills={addSkills}
      handlePrevious={handlePrevious}
    />,
    <SkillsTests handleNext={handleNext} handlePrevious={handlePrevious} />,
    <CVAdding
      handleNext={handleNext}
      uploadCv={uploadCv}
      handlePrevious={handlePrevious}
    />,
    <IDCheck
      handleNext={handleNext}
      uploadIdentification={uploadIdentification}
      handlePrevious={handlePrevious}
    />,
  ];

  // Step titles for display
  const stepTitles = [
    "Job Details",
    "English Test",
    "Skills",
    "Skills Tests",
    "Upload CV",
    "ID Check",
  ];

  const stepSubtexts = [
    "Provide your job-related details.",
    "Enter your English test scores.",
    "Select your skills.",
    "Complete optional skills tests.",
    "Upload your CV for review.",
    "Verify your identification.",
  ];

  return (
    <Flex height="100vh">
      <Box width="30%" display={{ base: "none", md: "block" }}>
        <Box
          height="100vh"
          width="100%"
          backgroundImage="url('/images/sidebar.jpeg')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundColor="#0065F2"
          backgroundBlendMode="multiply"
          display="flex"
          flexDirection="column" // Ensure items stack vertically
        >
          {/* Flex at the top */}
          <Flex alignItems="center" justifyContent="center" mt={6} mb={4} p={4}>
            {" "}
            {/* Optional padding for spacing */}
            <Image src="/images/logo.png" alt="Logo" boxSize="50px" mr={2} />
            <Text fontSize="xl" color="white">
              MyTeamAbroad
            </Text>
          </Flex>

          {/* VStack in the middle */}
          <Flex
            flexGrow={1} // Allow this flex to grow and take available space
            alignItems="center" // Center items vertically
            justifyContent="center" // Center items horizontally
            mt={-8}
          >
            <VStack spacing={10} mb={6} align="start">
              {" "}
              {stepTitles.map((title, index) => (
                <Flex key={index} alignItems="flex-start" direction="column">
                  <Flex
                    alignItems="flex-start" // Align items to the start of the flex container
                    direction="row"
                    textAlign="left" // Ensure text is aligned to the left
                    justifyContent="flex-start" // Align items at the start vertically
                    _hover={{cursor: "pointer"}}
                  >
                    {/* Circle Indicator */}
                    <Box
                      width={index === step ? "20px" : "12px"} 
                      height={index === step ? "20px" : "12px"} 
                      borderRadius="50%" // Make it circular
                      border="1px solid white" // No border if it's the current step
                      backgroundColor={index === step ? "white" : "transparent"} // White background if it's the current step
                      marginRight={4} // Space between circle and text
                    />
                    <Flex direction="column" p={0} mt="-8.5px">
                    <Text fontSize={index === step ? 24 : 16} fontWeight={index === step ? 700 : 300}  color="white" textAlign="left" p={0} m={0}>
                      {title}
                    </Text>

                    {/* Render subtext only for the current step */}
                    {index === step && (
                      <Text color="white" pl={0} fontWeight={300} fontSize={12}>
                        {stepSubtexts[index]}
                      </Text>
                    )}
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </VStack>
          </Flex>
        </Box>
      </Box>
      <Flex
        width={{ base: "100%", md: "70%" }}
        align="center"
        justify="center"
        bg="white"
        p={8}
      >
        <Box flex={1} p={4}>
          {/* Step Status Indicator */}

          {/* Render current step component */}
          {steps[step]}
        </Box>
      </Flex>
    </Flex>
  );
};

export default LoginProcess;

/*import React, { useState, useContext, useEffect } from "react";
import { Box, Button, Text, Flex, Image } from "@chakra-ui/react";
import Skills from "../../components/LoginProcess/Skills";
import JobDetails from "../../components/LoginProcess/JobDetails";
import EnglishTest from "../../components/LoginProcess/EnglishTest";
import IDCheck from "../../components/LoginProcess/IDCheck";
import CVAdding from "../../components/LoginProcess/CVAdding";
import SkillsTests from "../../components/LoginProcess/SkillsTests";
import { AuthContext } from "../../context";

const LoginProcess = () => {
  const [step, setStep] = useState(4);
  const {
    userData,
    uploadIdentification,
    uploadCv,
    addSkills,
    addEnglishTestScore,
    updateSalariesAndFullTime,
  } = useContext(AuthContext);

  // Handle switching to previous component
  const handlePrevious = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  // Handle switching to next component, add logic to call appropriate function for each step
  const handleNext = async () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  // List of components to render
  const steps = [
    <JobDetails
      handleNext={handleNext}
      updateSalariesAndFullTime={updateSalariesAndFullTime}
    />, // Update job details (salary, full-time status)
    <EnglishTest
      handlePrevious={handlePrevious}
      handleNext={handleNext}
      addEnglishTestScore={addEnglishTestScore}
    />, // Add English test score
    <Skills handleNext={handleNext} addSkills={addSkills} handlePrevious={handlePrevious} />, // Add skills
    <SkillsTests handleNext={handleNext} handlePrevious={handlePrevious}/>, // Skills tests (optional step)
    <CVAdding handleNext={handleNext} uploadCv={uploadCv} handlePrevious={handlePrevious}/>, // Upload CV
    <IDCheck
      handleNext={handleNext}
      uploadIdentification={uploadIdentification}
      handlePrevious={handlePrevious}
    />
  ];

  // Set the current step based on user data
  /*useEffect(() => {
    if (!userData) {
      setStep(0);
    } else if (!userData.maxMonthlySalary) {
      setStep(0);
    } else if (!userData.englishTest) {
      setStep(1);
    } else if (!userData.skills) {
      setStep(2);
    } else if (!userData.cv) {
      setStep(4);
    } else if (!userData.identification) {
      setStep(5);
    } else {
      setStep(6);
    }
  }, [userData]);

  return (
    <Flex height="100vh">
      <Box width="30%" display={{ base: "none", md: "block" }}>
        <Box
          height="100vh"
          width="100%"
          backgroundImage="url('/images/sidebar.jpeg')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundColor="#0065F2"
          backgroundBlendMode="multiply"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Flex alignItems="center">
            <Image src="/images/logo.png" alt="Logo" boxSize="50px" mr={2} />
            <Text fontSize="xl" color="white">
              MyTeamAbroad
            </Text>
          </Flex>
        </Box>
      </Box>
      <Flex
        width={{ base: "100%", md: "70%" }}
        align="center"
        justify="center"
        bg="white"
        p={8}
      >
        <Box flex={1} p={4}>
          {steps[step]}
        </Box>
      </Flex>
    </Flex>
  );
};

export default LoginProcess;*/
