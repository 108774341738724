import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import notify from "../../../utils/notify"; // Import your notify function

const CVAdding = ({ handleNext, uploadCv, handlePrevious }) => {
  const [linkedInLink, setLinkedInLink] = useState(""); // Store the LinkedIn link
  const [cvFile, setCvFile] = useState(null); // Store the uploaded CV file
  const [isError, setIsError] = useState(false); // For validation
  const [isUploading, setIsUploading] = useState(false); // Track upload status

  const sendCV = async () => {
    try {
      //await uploadCv();
      handleNext();
    } catch (error) {}
  };

  return (
    <Box py={5} px={64}>
      <Heading fontSize={34} size="lg" mb={4}>
        MTA CV
      </Heading>
      <Text fontSize={16} color="#707887">
        We will fill your MyTeam Abroad MTA CV with your linkedin profile, so
        please make sure your LinkedIn profile is accurate before submitting
        this the URL
      </Text>
      <FormControl isInvalid={isError} isRequired>
        <Input
          type="url"
          placeholder="Linkedin URL"
          borderRadius="8px"
          py={6}
          px={4}
          mt={9}
        />
      </FormControl>
      <Flex justifyContent="space-between" mt={10} px={0}>
        <Button
          px={6}
          sx={{
            borderRadius: "8px",
            background: "#DCEBFA",
            color: "#016DDA",
            textAlign: "center",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 600,
            _hover: { background: "#B9DBF5", color: "#005BB5" },
          }}
          onClick={handlePrevious}
        >
          Back
        </Button>
        <Button
          px={6}
          sx={{
            borderRadius: "8px",
            background: "#016DDA",
            color: "white",
            textAlign: "center",
            fontFamily: "Segoe UI",
            fontSize: "14px",
            fontWeight: 600,
            _hover: { background: "#004AA4" },
          }}
          onClick={sendCV}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default CVAdding;
