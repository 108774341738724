import React, { useMemo } from "react";
import {
  Button,
  FormControl,
  Select,
  Stack,
  HStack,
  IconButton,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import { CloseIcon, AddIcon } from "@chakra-ui/icons";
import { useForm, useFieldArray } from "react-hook-form";
import skills from "../../data/skills";
import notify from "../../utils/notify";

const SkillsModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, control, setValue } = useForm({
    defaultValues: {
      skills: [], 
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "skills",
  });

  const availableSkills = useMemo(() => {
    const selectedSkillNames = fields.map((skill) => skill.skillName);
    return skills.filter((skill) => !selectedSkillNames.includes(skill));
  }, [fields]);

  const handleAddSkill = (skillName) => {
    if (!skillName) return;

    if (fields.length >= 10) {
      notify("error", "You cannot add more than 10 skills");
      return;
    }

    append({ skillName, score: 100 });
    setValue("selectedSkill", "");
  };

  return (
    <>
      <Button
        rightIcon={<AddIcon />}
        px={8}
        sx={{
          borderRadius: "8px",
          background: "#016DDA",
          color: "white",
          textAlign: "center",
          fontFamily: "Segoe UI",
          fontSize: "14px",
          fontWeight: 600,
          _hover: { background: "#004AA4" },
        }}
        onClick={onOpen}
        py={5}
      >
        Add New Skill
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="40%" >
          <ModalHeader my={0}>Add new skills</ModalHeader>
          <ModalBody mt={0} pt={0}>
            <Text color="#707887" mt={0} mb={4}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem.
            </Text>

            <Stack spacing={4} p={0}>
              {fields.length > 0 && (
                <HStack wrap="wrap" spacing={2} mb={2}>
                  {fields.map((skill, index) => (
                    <HStack key={skill.id} spacing={0}>
                      <Button
                        size="sm"
                        variant="outline"
                        borderRadius="8px 0 0 8px"
                        background="#E8E9E9"
                        color="#596375"
                      >
                        {skill.skillName}
                      </Button>
                      <IconButton
                        aria-label="Remove Skill"
                        size="sm"
                        icon={<CloseIcon fontSize={8} />}
                        onClick={() => remove(index)}
                        borderRadius="0 8px 8px 0"
                        background="#E8E9E9"
                        borderLeft="none"
                        pr={4}
                      />
                    </HStack>
                  ))}
                </HStack>
              )}
              <HStack spacing={4}>
                <FormControl isRequired>
                  <Select
                    {...register("selectedSkill")}
                    placeholder="Skill"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                    color="#000F2B"
                    opacity={0.65}
                  >
                    {availableSkills.map((skill, index) => (
                      <option key={index} value={skill}>
                        {skill}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  px={8}
                  py={6}
                  onClick={() =>
                    handleAddSkill(
                      control._formValues.selectedSkill
                    )
                  }
                  sx={{
                    borderRadius: "8px",
                    background: "#DCEBFA",
                    color: "#016DDA",
                    textAlign: "center",
                    fontFamily: "Segoe UI",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    _hover: {
                      background: "#B9DBF5",
                      color: "#005BB5",
                    },
                  }}
                >
                  Add Skill +
                </Button>
              </HStack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <Button
                px={7}
                sx={{
                  borderRadius: "8px",
                  background: "#DCEBFA",
                  color: "#016DDA",
                  textAlign: "center",
                  fontFamily: "Segoe UI",
                  fontSize: "14px",
                  fontWeight: 600,
                  _hover: { background: "#B9DBF5", color: "#005BB5" },
                }}
                onClick={onClose}
              >
                Back
              </Button>
              <Button
                px={7}
                sx={{
                  borderRadius: "8px",
                  background: "#016DDA",
                  color: "white",
                  textAlign: "center",
                  fontFamily: "Segoe UI",
                  fontSize: "14px",
                  fontWeight: 600,
                  _hover: { background: "#004AA4" },
                }}
                onClick={onClose}
              >
                Take Test
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SkillsModal;