const skills = [
  "JavaScript",
  "Python",
  "Java",
  "C#",
  "React",
  "Node.js",
  "SQL",
  "HTML/CSS",
  "Git",
  "Machine Learning",
  "Django",
  "Ruby on Rails",
  "PHP",
  "TypeScript",
  "Angular",
  "Vue.js",
  "AWS",
  "Docker",
  "Kubernetes",
  "Blockchain",
  "Cybersecurity",
  "Data Analysis",
  "DevOps",
  "APIs",
  "Responsive Design",
  "GraphQL",
  "Swift",
  "Go",
  "Rust",
  "iOS Development",
  "Android Development",
  "C++",
  "R",
  "MATLAB",
  "Power BI",
  "Tableau",
  "SAS",
  "Excel",
  "Microservices",
  "User Experience (UX)",
  "User Interface (UI)",
  "Data Visualization",
  "Computer Networking",
  "Cloud Computing",
  "Scrum",
  "Agile Methodologies",
  "Content Management Systems (CMS)",
  "Salesforce",
  "NLP",
  "Penetration Testing",
  "Internet of Things (IoT)",
  "Augmented Reality (AR)",
  "Virtual Reality (VR)",
  "Digital Transformation",
];

export default skills;
