import React, { useContext } from "react";
import {
  Box,
  Button,
  Text,
  Heading,
  Link,
  Flex,
  Image,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { AuthContext } from "../../context";
import { useParams } from "react-router-dom";

const SignupConfirmation = () => {
  const { resendConfirmationEmail } = useContext(AuthContext); // Fetch the function from context
  const { email } = useParams();

  const handleResendEmail = async () => {
    try {
      await resendConfirmationEmail(email); // Call the function from context
    } catch (error) {
      console.error("Error resending confirmation email:", error);
    }
  };

  return (
    <Flex height="100vh" direction="row">
      <Box width="30%" display={{ base: "none", md: "block" }}>
        <Box
          height="100vh"
          width="100%"
          backgroundImage="url('/images/sidebar.jpeg')" // Set the background image
          backgroundSize="cover" // Ensure the image covers the entire box
          backgroundPosition="center" // Center the image
          backgroundRepeat="no-repeat" // Prevent repeating the image
          backgroundColor="#0065F2" // Fallback color
          backgroundBlendMode="multiply" // Blend mode
          display="flex" // Use flexbox for centering
          alignItems="center" // Center items vertically
          justifyContent="center" // Center items horizontally
        >
          <Flex alignItems="center" _hover={{ cursor: "pointer" }}>
            {" "}
            {/* Horizontal flex for logo and text */}
            <Image
              src="/images/logo.png"
              alt="Logo"
              boxSize="50px"
              mr={2}
            />{" "}
            {/* Logo with margin right */}
            <Text fontSize="xl" color="white">
              MyTeamAbroad
            </Text>{" "}
            {/* Text with white color */}
          </Flex>
        </Box>
      </Box>
      <Flex
        width={{ base: "100%", md: "70%" }}
        align="center"
        justify="center"
        bg="white"
        p={4}
      >
        <Box maxWidth="960px" textAlign="center">
          <Heading as="h1" mb={5} fontSize={40}>
            Sign up
          </Heading>

          <Text mb={8} color="#707887">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </Text>

          <Text mb={3} color="#707887">
            Didn’t receive a message?
          </Text>
          <Button
            mt={2}
            py={6}
            px={6}
            borderRadius="8px"
            background="#016DDA"
            size="md"
            mb={8}
            color="white"
            _hover={{ background: "#016DDA" }}
            onClick={() => handleResendEmail()}
          >
            Resend Email
          </Button>

          <Text color="#707887">
            Do you already have an account?{" "}
            <Link
              color="#016DDA"
              fontWeight={700}
              to="/"
              as={RouterLink}
              _hover={{ textDecoration: "none" }}
            >
              Log In
            </Link>
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SignupConfirmation;
