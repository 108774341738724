import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Flex,
  Button,
} from "@chakra-ui/react";

const ChangePassword = () => {
  return (
    <Box
      p={5}
      borderRadius="12px"
      background="white"
    >
      <form>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Current Password</FormLabel>
            <Input
              type="password"
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            />
          </FormControl>
        </Stack>
        <Flex justifyContent="flex-end" mt={4} px={4}>
          <Button
            sx={{
              borderRadius: "8px",
              background: "#016DDA",
              color: "white",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#004AA4" },
            }}
          >
            Change Password
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default ChangePassword;