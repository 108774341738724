import React from "react";
import { Box, VStack, Link, HStack } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();

  return (
    <Box width="250px" padding="20px">
      <VStack align="start" spacing={4} borderRadius="4px">
        <HStack
          bg={location.pathname === "/" ? "#016DDA" : "transparent"}
          borderRadius="4px"
          pl={3}
          pr={10}
        >
          <Box width="24px" height="24px" borderRadius="5px" bg="#DCEBFA" />
          <Link
            as={NavLink}
            to="/"
            style={{ textDecoration: "none" }}
            color={location.pathname === "/" ? "white" : "black"}
            padding="8px"
            borderRadius="md"
          >
            Dashboard
          </Link>
        </HStack>
        <HStack
          bg={location.pathname.includes("/setting") ? "#016DDA" : "transparent"}
          borderRadius="4px"
          pl={3}
          pr={10}
        >
          <Box width="24px" height="24px" borderRadius="5px" bg="#DCEBFA" />
          <Link
            as={NavLink}
            to="/settings"
            style={{ textDecoration: "none" }}
            color={location.pathname.includes("/setting") ? "white" : "black"}
            padding="8px"
            borderRadius="md"
          >
            Settings
          </Link>
        </HStack>
      </VStack>
    </Box>
  );
};

export default Sidebar;
