import React from "react";
import Layout from "../../components/Admin/AdminLayout";
import { useLocation } from "react-router-dom";
import TableCandidates from "../../components/Admin/TableCandidates";
import TablePartners from "../../components/Admin/TablePartners";

const Admin = () => {
  const location = useLocation();
  const fragment = location.hash; // Obtenemos el fragmento de la URL

  // Eliminamos el '#' del fragmento para obtener solo 'candidates'
  const param = fragment.substring(1);
  console.log(param);
  return (
    <Layout>
      {param === "candidates" ? <TableCandidates /> : <TablePartners />}
    </Layout>
  );
};

export default Admin;
