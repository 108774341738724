import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Heading, Text, Input, Button, Flex } from "@chakra-ui/react";
import { AuthContext } from "../../context";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { confirmEmailCode, resendConfirmationEmail, confirmPhoneCode } =
    useContext(AuthContext);
  const { email, emailCode } = useParams(); // Assuming we get the 'email' and 'emailCode' from URL params

  // State to hold the 6-digit code (each as a separate element)
  const [code, setCode] = useState(new Array(6).fill(""));

  // Resend confirmation email
  const handleResendEmail = async () => {
    try {
      await resendConfirmationEmail(email);
    } catch (error) {
      console.error("Error resending confirmation email:", error);
    }
  };

  // Handle change for each mini input
  const handleChange = (value, index) => {
    const newCode = [...code];

    if (/^\d$/.test(value)) {
      // Only accept one digit (0-9)
      newCode[index] = value;

      // Move to next input if not last and a digit is entered
      if (value && index < 5) {
        document.getElementById(`code-input-${index + 1}`).focus();
      }
    } else if (value === "") {
      // Handle backspace: Clear current input and focus on previous input
      newCode[index] = "";
      setCode(newCode);

      if (index > 0) {
        document.getElementById(`code-input-${index - 1}`).focus();
      }
    }

    setCode(newCode);
  };

  // Handle phone code confirmation on button click
  const handleConfirmPhone = async () => {
    try {
      const phoneCode = code.join(""); // Assuming the same 'code' input is used for phone
      if (phoneCode.length === 6) {
        await confirmPhoneCode(email, phoneCode); // Verify phone code
        navigate("/"); // Navigate after phone verification
      } else {
        alert("Please enter a valid 6-digit phone code.");
      }
    } catch (error) {
      console.error("Phone verification failed", error);
    }
  };

  // Automatically verify the email code if 'emailCode' is provided via params
  useEffect(() => {
    if (email && emailCode) {
      const autoVerifyEmailCode = async () => {
        try {
          await confirmEmailCode(email, emailCode); // Verify the email code
        } catch (error) {
          console.error("Automatic email verification failed", error);
        }
      };
      autoVerifyEmailCode();
    }
  }, [email, emailCode]);

  return (
    <Flex height="100vh" direction="row">
      <Box width="30%" display={{ base: "none", md: "block" }}>
        <Box
          height="100vh"
          width="100%"
          backgroundImage="url('/images/sidebar.jpeg')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundColor="#0065F2"
          backgroundBlendMode="multiply"
          display="flex"
          alignItems="center"
          justifyContent="center"
        ></Box>
      </Box>
      <Flex
        width={{ base: "100%", md: "70%" }}
        align="center"
        justify="center"
        bg="white"
        p={8}
      >
        <Box
          maxW="700px"
          width="70%"
          mt={0}
          p={2}
          background="white"
          textAlign="center"
        >
          <Heading as="h1" mb={3}>
            Authenticator
          </Heading>

          <Text mb={8} color="#707887">
            Lorem Ipsum is simply dummy text
          </Text>

          {/* Six mini inputs for the code */}
          <Flex justify="center" mb={10} gap={3}>
            {code.map((digit, index) => (
              <Input
                key={index}
                id={`code-input-${index}`}
                value={digit}
                maxLength={1}
                onChange={(e) => handleChange(e.target.value, index)}
                textAlign="center"
                size="md"
                width="6rem"
                height="6rem"
                borderRadius="8px"
                border="1px solid #016DDA"
                background="white"
                _focus={{ borderColor: "blue.500" }}
                type="text"
                inputMode="numeric"
                fontSize={48}
                fontWeight={600}
              />
            ))}
          </Flex>

          <Flex direction="column" gap={4} align="center" justify="center">
            <Button
              color="#016DDA"
              size="md"
              borderRadius="8px"
              background="#DCEBFA"
              _hover={{ background: "#DCEBFA" }}
              width={40}
              onClick={handleConfirmPhone}
            >
              Authenticate
            </Button>
            <Button
              size="md"
              color="white"
              borderRadius="8px"
              background="#016DDA"
              _hover={{ background: "#016DDA" }}
              width={60}
              onClick={handleResendEmail}
            >
              Resend Email
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default VerifyEmail;
