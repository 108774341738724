import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  Text,
  Flex,
  Input,
  Heading,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import SkillsModal from "../../SkillsModal";

const SkillsTable = () => {
  const [filter, setFilter] = useState("");

  const tasks = [
    {
      taken: "2024/10/30",
      seniority: "Senior",
      skill: "UI/UX Design",
      score: 85,
      passed: true,
      retake: "Already taken",
    },
    {
      taken: "2024/10/25",
      seniority: "Mid",
      skill: "Backend Development",
      score: 90,
      passed: true,
      retake: "Already taken",
    },
    {
      taken: "2024/11/05",
      seniority: "Junior",
      skill: "QA Testing",
      score: 70,
      passed: false,
      retake: "Already taken",
    },
    {
      taken: "2024/11/10",
      seniority: "Senior",
      skill: "Security",
      score: 95,
      passed: true,
      retake: "Not available",
    },
    {
      taken: "2024/11/15",
      seniority: "Mid",
      skill: "DevOps",
      score: 88,
      passed: true,
      retake: "Not available",
    },
  ];

  const filteredTasks = tasks.filter((task) =>
    task.skill.toLowerCase().includes(filter.toLowerCase()) ||
    task.seniority.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <Box backgroundColor="white" p={6} borderRadius="10px">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading  fontSize={28}>Skills</Heading>
        <Flex alignItems="center">
          <InputGroup mr={4}>
            <InputLeftElement pointerEvents="none" mt="2px">
              <SearchIcon color="gray.300" pr={0}/>
            </InputLeftElement>
            <Input
              placeholder="Search"
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={10}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </InputGroup>
          <SkillsModal />
        </Flex>
      </Flex>
      <TableContainer backgroundColor="white">
        <Table>
          <Thead backgroundColor="#DCEBFA" borderRadius="5px 5px 0px 0px">
            <Tr>
              <Th fontWeight={400} color="#000F2B" fontSize={14}>
                Seniority
              </Th>
              <Th fontWeight={400} color="#000F2B" fontSize={14}>
                Skill
              </Th>
              <Th fontWeight={400} color="#000F2B" fontSize={14}>
                Taken
              </Th>
              <Th fontWeight={400} color="#000F2B" fontSize={14}>
                Score
              </Th>
              <Th fontWeight={400} color="#000F2B" fontSize={14}>
                Passed
              </Th>
              <Th
                fontWeight={400}
                color="#000F2B"
                fontSize={14}
                textAlign="right"
              >
                Retake
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredTasks.map((task, index) => (
              <Tr key={index}>
                <Td fontWeight={500} color="#000F2B" fontSize={14}>
                  {task.seniority}
                </Td>
                <Td fontWeight={400} color="#000F2B" fontSize={14}>
                  {task.skill}
                </Td>
                <Td fontWeight={400} color="#000F2B" fontSize={14}>
                  {task.taken}
                </Td>
                <Td fontWeight={400} color="#000F2B" fontSize={14}>
                  {task.score}
                </Td>
                <Td fontWeight={400} color="#000F2B" fontSize={14}>
                  <Box
                    width="60px"
                    height="30px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color={task.passed ? "#00B833" : "#FF0000"}
                    bg={task.passed ? "#E8FFDD" : "#FDD"}
                    borderRadius="md"
                    boxShadow="sm"
                  >
                    <Text fontSize={16} fontWeight="bold">
                      {task.passed ? "Yes" : "No"}
                    </Text>
                  </Box>
                </Td>
                <Td
                  fontWeight={400}
                  color="#000F2B"
                  fontSize={14}
                  width="40%"
                  textAlign="right"
                >
                  {task.retake}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SkillsTable;