import React, { useState, useContext } from "react";
import {
  Box,
  Flex,
  FormControl,
  Input,
  Button,
  Stack,
  Image,
  Text,
  InputGroup,
  InputRightElement,
  IconButton
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  
  const [showPassword, setShowPassword] = useState(false);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loginToApp = async (data) => {
    try {
      await login(data.email, data.password);
      navigate('/'); // Redirect after successful login
    } catch (err) {
      // Handle error if needed
    }
  };

  return (
    <Flex height="100vh">
      <Box width="30%" display={{ base: "none", md: "block" }}>
        <Box
          height="100vh"
          width="100%"
          backgroundImage="url('/images/sidebar.jpeg')"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundColor="#0065F2"
          backgroundBlendMode="multiply"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Flex alignItems="center">
            <Image src="/images/logo.png" alt="Logo" boxSize="50px" mr={2} />
            <Text fontSize="xl" color="white">MyTeamAbroad</Text>
          </Flex>
        </Box>
      </Box>
      <Flex
        width={{ base: "100%", md: "70%" }}
        align="center"
        justify="center"
        bg="white"
        p={8}
      >
        <Box width="100%" maxWidth="600px" p={6} bg="white" borderRadius="md">
          <Flex direction="column" mb={4}>
            <Text fontSize="2xl" fontWeight="bold">Log In</Text>
            <Text fontSize="sm" color="gray.500">Please select your account</Text>
          </Flex>
          
          {/* Wrap inputs and buttons in a form */}
          <form onSubmit={handleSubmit(loginToApp)}>
            <Box>
              <Stack spacing={4}>
                <FormControl id="email" isRequired>
                  <Input
                    type="email"
                    placeholder="Email"
                    borderRadius="8px"
                    border="1px solid #E5E6E6"
                    background="white"
                    h={12}
                    {...register("email", { required: "Email is required" })}
                  />
                  {errors.email && <Text color="red">{errors.email.message}</Text>}
                </FormControl>
                <FormControl id="password" isRequired>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      borderRadius="8px"
                      border="1px solid #E5E6E6"
                      background="white"
                      h={12}
                      {...register("password", { required: "Password is required" })}
                    />
                    <InputRightElement mt={1}>
                      <IconButton
                        onClick={togglePasswordVisibility}
                        icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        aria-label="Toggle Password Visibility"
                        variant="link"
                        size="sm"
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.password && (
                    <Text color="red">{errors.password.message}</Text>
                  )}
                </FormControl>
              </Stack>
            </Box>

            {/* Buttons inside the form */}
            <Flex justifyContent="space-between" mt={8}>
              <Button
                as={RouterLink}
                to="/signup"
                px={6}
                sx={{
                  borderRadius: "8px",
                  background: "#DCEBFA",
                  color: "#016DDA",
                  textAlign: "center",
                  fontFamily: "Segoe UI",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  _hover: {
                    background: "#B9DBF5",
                    color: "#005BB5",
                  },
                }}
              >
                Create Account
              </Button>
              {/* Set button type to submit */}
              <Button
                type="submit" // Set button type to submit
                px={6}
                sx={{
                  borderRadius: "8px",
                  background: "#016DDA",
                  color: "#FFF",
                  textAlign: "center",
                  fontFamily: "Segoe UI",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                  _hover: {
                    background: "#005BB5",
                  },
                }}
              >
                Login
              </Button>
            </Flex>
          </form>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Login;