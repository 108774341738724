import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  HStack,
  Text,
  Flex,
  Button,
} from "@chakra-ui/react";

const DesiredSalary = () => {
  return (
    <Box
      p={5}
      borderRadius="12px"
      background="white"
    >
      <form>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Minimum Payment</FormLabel>
            <HStack>
              <Input
                type="number"
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
              <Text>to</Text>
              <Input
                type="number"
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
              />
            </HStack>
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Modality</FormLabel>
            <Select
              borderRadius="8px"
              border="1px solid #E5E6E6"
              background="white"
              h={12}
            >
              <option value="">Select a modality</option>
              <option value="part-time">Part-Time</option>
              <option value="full-time">Full-Time</option>
            </Select>
          </FormControl>
        </Stack>
        <Flex justifyContent="flex-end" mt={4} px={4}>
          <Button
            sx={{
              borderRadius: "8px",
              background: "#016DDA",
              color: "white",
              textAlign: "center",
              fontFamily: "Segoe UI",
              fontSize: "14px",
              fontWeight: 600,
              _hover: { background: "#004AA4" },
            }}
          >
            Edit
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default DesiredSalary;