import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Badge,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  FormControl,
} from "@chakra-ui/react";
import data from "./data.json"; // Importamos los datos dummy
import { useForm } from "react-hook-form";

const TablePartners: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [dataList, setDataList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;
  const pagesVisited = pageNumber * itemsPerPage;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
  });

  useEffect(() => {
    setDataList(data);
    setFilteredData(data);
  }, []);

  const displayData = filteredData
    .slice(pagesVisited, pagesVisited + itemsPerPage)
    .map((item) => (
      <Tr key={item.id}>
        <Td>{item.name}</Td>
        <Td>{item.email}</Td>
        <Td>
          {item.isActive ? (
            <Badge
              color="#00B833"
              bg="#E8FFDD"
              padding={"5px 15px"}
              borderRadius={"4px"}
            >
              Active
            </Badge>
          ) : (
            <Badge
              color="#FF0000"
              bg="#FFDDDD"
              padding={"5px 15px"}
              borderRadius={"4px"}
            >
              Not Active
            </Badge>
          )}
        </Td>
        <Td>
          <HStack display="flex" justifyContent="end" wrap="wrap" gap="6">
            {item.isActive ? (
              <Button
                size="sm"
                borderRadius="8px"
                borderColor="#016DDA"
                border="1px"
                bg="white"
                color="#016DDA"
              >
                Deactivate
              </Button>
            ) : (
              <Button size="sm" borderRadius="8px" bg="#DCEBFA" color="#016DDA">
                Activate
              </Button>
            )}
            <Button size="sm" borderRadius="8px" bg="#016DDA" color="#FFFFFF">
              Delete
            </Button>
          </HStack>
        </Td>
      </Tr>
    ));

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = e.target.value;
    const filteredResults = dataList.filter((item) =>
      item.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setFilteredData(filteredResults);
  };

  return (
    <Box>
      <Box justifyContent="space-between" display="flex" width="100%">
        Partners
        <Box justifyContent="space-between" display="flex" gap="4px">
          <Input
            placeholder="Find by name..."
            onChange={handleSearch}
            marginBottom={4}
            width="50%"
          />
          <Button
            borderRadius="8px"
            bg="#016DDA"
            color="#FFFFFF"
            onClick={onOpen}
          >
            Add New Partner +
          </Button>
        </Box>
      </Box>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>State</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>{displayData}</Tbody>
      </Table>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Partner</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box fontSize="sm">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              id erat massa. Vestibulum sit amet ante metus. Ut in cursus dui,
              nec mattis ante. Pellentesque ornare varius nisl et pretium.
            </Box>
            <HStack marginTop="1.5em" spacing={4}>
              <FormControl isRequired>
                <Input
                  {...register("firstName", { required: true })}
                  placeholder="First Name"
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                />
              </FormControl>

              <FormControl isRequired>
                <Input
                  {...register("lastName", { required: true })}
                  placeholder="Last Name"
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                />
              </FormControl>
            </HStack>
            <HStack marginTop="1.5em" spacing={4}>
              <FormControl isRequired>
                <Input
                  {...register("email", { required: true })}
                  placeholder="Email"
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                />
              </FormControl>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              mr={3}
              borderRadius="8px"
              bg="#016DDA"
              color="#FFFFFF"
            >
              Agregar
            </Button>
            <Button
              onClick={onClose}
              borderRadius="8px"
              bg="#DCEBFA"
              color="#016DDA"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TablePartners;
