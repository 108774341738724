import React from "react";
import {
  Box,
  FormControl,
  Input,
  Select,
  Stack,
  HStack,
  Text,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";

const JobDetails = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      minMonthlySalary: "",
      maxMonthlySalary: "",
      position: "",
      modality: "",
    },
  });

  const onSubmit = async (data) => {};

  return (
    <Flex
      pb={5}
      px={5}
      pt={2}
      borderRadius="12px"
      backgroundColor="white"
      width="100%"
      flex="1"
      direction="column"
    >
      <Text color="#49424D" fontSize={22} fontWeight={700} mb={3}>
        Your Dream Job
      </Text>
      <Text color="#707887" fontSize={16} fontWeight={400}>
        We will fill your MyTeam Abroad MTA CV with your linkedin profile, so
        please make sure your LinkedIn profile is accurate before submitting
        this the URL
      </Text>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <FormControl isRequired>
              <Text my={4} color="#000F2B">
                Desired monthly range
              </Text>
              <HStack>
                <Input
                  type="number"
                  {...register("minMonthlySalary", {
                    required: "Minimum salary is required",
                    min: {
                      value: 0,
                      message: "Minimum salary must be positive",
                    },
                  })}
                  placeholder="USD"
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                  color="#000F2B"
                  opacity={0.65}
                  _placeholder={{ color: "#000F2B", opacity: 0.65 }}
                />
                <Text color="#000F2B" opacity={0.65}>
                  To
                </Text>
                <Input
                  type="number"
                  placeholder="USD"
                  {...register("maxMonthlySalary", {
                    required: "Maximum salary is required",
                    validate: (value) =>
                      value >
                        parseFloat(
                          document.getElementsByName("minMonthlySalary")[0]
                            ?.value
                        ) ||
                      "Maximum salary must be greater than minimum salary",
                  })}
                  borderRadius="8px"
                  border="1px solid #E5E6E6"
                  background="white"
                  h={12}
                  color="#000F2B"
                  opacity={0.65}
                  _placeholder={{ color: "#000F2B", opacity: 0.65 }}
                />
              </HStack>
              {errors.minMonthlySalary && (
                <Text color="red">{errors.minMonthlySalary.message}</Text>
              )}
              {errors.maxMonthlySalary && (
                <Text color="red">{errors.maxMonthlySalary.message}</Text>
              )}
            </FormControl>

            <FormControl isRequired>
              <Select
                {...register("modality", { required: "Modality is required" })}
                borderRadius="8px"
                border="1px solid #E5E6E6"
                background="white"
                h={12}
                _placeholder={{ color: "#000F2B", opacity: 0.65 }}
                color="#000F2B"
                opacity={0.65}
                placeholder="Modality (Part/Full Time)"
              >
                <option value="part-time">Part-Time</option>
                <option value="full-time">Full-Time</option>
              </Select>
              {errors.modality && (
                <Text color="red">{errors.modality.message}</Text>
              )}
            </FormControl>
          </Stack>
        </form>
      </Box>
      <Button
        borderRadius="8px"
        background="#016DDA"
        color="white"
        _hover={{ background: "#016DDA" }}
        mt={6}
        fontWeight={400}
      >
        Edit
      </Button>
    </Flex>
  );
};

export default JobDetails;
