import React from "react";
import { Flex } from "@chakra-ui/react";
import SkillsTable from "../../components/Home/SkillsTable";
import JobDetailsDashboard from "../../components/Home/JobDetailsDashboard";
import CVDashboard from "../../components/Home/CVDashboard";
const PercentageContainer = () => {
  return (
    <Flex direction="column" gap={8}>
      <Flex
        direction="row"
        justify="space-between"
        align="center"
        gap={8}
        wrap="wrap"
      >
        <JobDetailsDashboard flex="1"/>
        <CVDashboard flex="1"/>
      </Flex>
      <SkillsTable />
    </Flex>
  );
};

export default PercentageContainer;
