import { Box, Switch, Text, VStack, HStack, Button, Heading, StackDivider } from "@chakra-ui/react";

const Notifications = () => {
  const notifications = [
    { label: "Email notifications", description: "Keep informed and connected with notifications from us", isEnabled: true },
    { label: "Notifications from us", description: "Keep informed and connected with notifications from us", isEnabled: true },
    { label: "Message notifications", description: "Keep informed and connected with notifications from us", isEnabled: false },
    { label: "Reminders", description: "Keep informed and connected with notifications from us", isEnabled: false },
    { label: "ABCASDASD", description: "Keep informed and connected with notifications from us", isEnabled: true },
  ];

  return (
    <Box w="full" p={6} background="white" borderRadius="md">
      <Heading as="h3" size="lg" mb={4}>Notifications</Heading>
      <VStack divider={<StackDivider borderColor="gray.200" />} spacing={4} align="stretch">
        {notifications.map((notification, index) => (
          <HStack key={index} justify="space-between">
            <VStack align="start" spacing={0}>
              <Text fontWeight="medium">{notification.label}</Text>
              <Text fontSize="sm" color="gray.500">{notification.description}</Text>
            </VStack>
            <Switch colorScheme="blue" isChecked={notification.isEnabled} />
          </HStack>
        ))}
      </VStack>
      <HStack mt={6} justify="flex-end">
        <Button variant="outline" colorScheme="blue">Cancel</Button>
        <Button colorScheme="blue">Save</Button>
      </HStack>
    </Box>
  );
}

export default Notifications;
