export const AuthReducer = (state, action) => {
    switch (action.type) {        
      case "SET_LOGIN_TOKEN":
        return {
          ...state,
          loginToken: action.payload,
        };
  
      case "SET_USER_DATA":
        return {
          ...state,
          userData: action.payload,
        };
  
      case "CLEAR_STATE":
        return {
          loginToken: null,
          userData: null,
        };
  
      default:
        return state;
    }
  };
  