import React from "react";
import { Box, Progress, Text, Flex, Button } from "@chakra-ui/react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CVDashboard = () => {
  const data = [
    { label: "To define", percentage: 75 },
    { label: "To define", percentage: 50 },
    { label: "To define", percentage: 90 },
  ];

  const circlePercentage = 85;

  return (
    <Flex
      width="100%"
      flex="1"
      direction="column"
      backgroundColor="white"
      pb={5}
      px={5}
      pt={2}
      borderRadius="12px"
    >
      <Flex
        spacing={6}
        justifyContent="space-between"
        width="100%"
        direction="row"
        mb="64px"
      >
        <Flex direction="column" width="55%">
          <Text color="#49424D" fontSize={24} fontWeight={700} mb={5}>
            Overall
          </Text>
          {data.map((item, index) => (
            <Flex key={index} width="100%" direction="row">
              <Flex direction="column" width="100%">
              <Text mb={3} color="#000F2B" fontSize={18}>{item.label}</Text>
              <Progress
                value={item.percentage}
                size="md"
                width="100%"
                sx={{
                  borderRadius: "8px",
                  "& > div": {
                    borderRadius: "8px",
                    bg: "#016DDA",
                  },
                  bg: "gray.200",
                }}
              />
              </Flex>
              <Text mt={7} pt={1} ml={2} color="#000F2B" opacity={0.65}>100</Text>
            </Flex>
          ))}
        </Flex>

        <Box height="200px" width="200px" pt={10}>
          <CircularProgressbar
            value={circlePercentage}
            text={`${circlePercentage}%`}
            color="black"
            styles={{
              path: {
                stroke: `#016DDA`,
                strokeLinecap: "round",
                transition: "stroke-dashoffset 0.5s ease 0s",
              },
              trail: {
                stroke: "#E5E6E6",
              },
              text: {
                fontSize: "18px",
                fontWeight: "500", 
                fill: "black",
              },
            }}
          />
        </Box>
      </Flex>
      <Button
        borderRadius="8px"
        background="#016DDA"
        color="white"
        _hover={{ background: "#016DDA" }}
        fontWeight={400}
      >
        Edit CV Text
      </Button>
    </Flex>
  );
};

export default CVDashboard;
