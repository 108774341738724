import React from "react";
import { Box, Link } from "@chakra-ui/react";

const LinkBox: React.FC<{ children: React.ReactNode; href: string }> = ({
  children,
  href,
}) => {
  return (
    <Link
      _hover={{
        borderRadius: "10px",
        background: "#4A58EC",
      }}
      href={href}
    >
      <Box
        display="flex"
        padding="8px 16px"
        alignItems="center"
        gap={4}
        alignSelf="stretch"
        _hover={{
          borderRadius: "10px",
          background: "#4A58EC",
          color: "#fff",
        }}
      >
        {children}
      </Box>
    </Link>
  );
};

const Sidebar: React.FC = () => {
  return (
    <Box
      position="fixed"
      padding={"30px 18px"}
      left={0}
      top={0}
      bottom={0}
      width={200}
      bg="#fff"
    >
      {/* Imagen */}
      <img src="/public/images/large-logo.svg" alt="Logo" />
      {/* Lista de items clicables */}
      <Box marginTop={"40px"}>
        <Box marginTop={2}>
          <LinkBox href={`admin#partners`}>
            <Box
              width="24px"
              height="24px"
              borderRadius="5px"
              bg="#DCEBFA"
            ></Box>
            <Box>Partners</Box>
          </LinkBox>
        </Box>
        <Box marginTop={2}>
          <LinkBox href={`admin#candidates`}>
            <Box
              width="24px"
              height="24px"
              borderRadius="5px"
              bg="#DCEBFA"
            ></Box>
            <Box>Candidates</Box>
          </LinkBox>
        </Box>
      </Box>
    </Box>
  );
};

const Navbar: React.FC = () => {
  return (
    <Box
      position="fixed"
      top={0}
      width="100%"
      height={24}
      bg="#ffffff"
      color="white"
    >
      {/* Contenido del Navbar */}
      Navbar
    </Box>
  );
};

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box className="layout" bg="#F5F5F5" width="100vw" height="100vh">
      <Sidebar />
      <Box marginLeft={184} marginTop={24} padding={4}>
        <Navbar />
        <Box padding={35}>
          <Box bg="white" padding={"24px"} borderRadius={10}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
